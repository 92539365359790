.main-header{
  .header-mobile{
    display:none;
  }
}

.main-header{
  width:100%;
  z-index:1000;
  position: absolute;
  top: 0;
  @include transition(all 0.4s ease);
  @include respond-to('medium'){
    .header-desktop{
      display: none;
    }
    .header-mobile{
      display:block;
      & > a{
        margin-left: 20px;
        margin-top: 10px;
        display: inline-block;
        float: left;
        & > img{
          width: 85px;
        }
      }
      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after{
        background-color: #415667;
      }
      .hamburger{
        padding: 25px 20px;
      }
    }
  }
}

.mobile-tel{
  display: inline-block;
  margin-top: 23px;
  i{
    color: #f7991b;
    font-size: 42px;
  }
}

.sb-slidebar{
  background-color: #f5f5f5;
  z-index: 999999;
}

.mobile-menu-btn{
  float: right;
}

.sb-right .sb-menu,
.sb-left .sb-menu{
  & li{
    border-bottom: 0;
    & a{
      color: #f7991b;
      border-bottom: 1px solid rgba(0,0,0,.1);
      font-size: 15px;
      &:hover{
        background-color: #eee;
      }
    }
    & ul{
      li{
        padding-right: 0;
      }
    }
  }
  & ul{
    padding: 0;
    li > a{
      padding-right: 35px;
    }
  }
}

.header-desktop{
  height: 100%;

}

.main-navi{
  display: inline-block;
  & > ul{
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 40px 40px 0 0;
    @include respond-to('large'){
      padding: 40px 10px 0 0;
    }
    & > li{
      float: right;
      margin-left: 3px;
      position: relative;
      & > a{
        font-size: 20px;
        color: #3c5663;
        padding: 4px 10px;
        display: block;
        transition: all 0.2s;
        @include border-radius(3px);
        @include respond-to('large'){
          font-size: 18px;
        }
        &:hover{
          background-color: #f7991b;
          color: #fff;
        }
        &.active{
          background-color: #f7991b;
          color: #fff;
        }
      }
    }
  }
}

.logo{
  position: absolute;
  top: 40px;
  left: 60px;
}

#fixed-top.scrolling{
  height: 80px;
  background-color: #fff;
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.3);
  position: fixed;
  .main-navi{
    & > ul{
      padding-top: 25px;
      padding-right: 20px;
    }
  }
  .logo{
    position: absolute;
    top: 25px;
    left: 20px;
  }
}

/*LTR*/
html[dir="ltr"]{
  .mobile-menu-btn,
  .mobile-tel{
    float: left;
  }

  .main-header .header-mobile{
    & > a{
      float: right;
      margin-left: 0;
      margin-right: 20px;
    }
  }
}
