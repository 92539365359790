.breadcrumb{
  background-color: transparent;
  margin-top: -45px;
  margin-bottom: 10px;
  padding: 8px 0 8px 15px;
  & > li{
    &:last-child{
      & > a{
        color: #f79319;
        text-decoration: none;
      }
    }
    & > a{
      font-size: 16px;
      color: #777777;
    }
  }
}
