.wrap-content-right{
  height: 100%;
  padding-top: 55px;
  position: relative;
  @include respond-to('medium'){
    padding-top: 20px;
  }
  &:before{
    content: "";
    position: absolute;
    width: 20px;
    top: -9999px;
    bottom: -9999px;
    left: 0;
    box-shadow: -8px 0px 15px 0px rgba(0,0,0,0.1);
  }
}

.wrap-content-left{
  height: 100%;
  padding-top: 80px;
  padding-right: 50px;
  @include respond-to('huge'){
    padding-right: 20px;
  }
  @include respond-to('medium'){
    padding-top: 0;
  }
  & > p{
    margin-top: 40px;
    @include respond-to('medium'){
      text-align: center;
      margin-top: 20px;
    }
    & > span{
      font-size: 18px;
      color: #000;
    }
  }
}

.overflow-hidden{
  overflow: hidden;
}

.sub-title{
  font-size: 24px;
  font-weight: 800;
  color: #f79319;
  margin: 20px 0;
  @include respond-to('small'){
    font-size: 20px;
  }
}

.wrap-content{
  padding-left: 27px;
  padding-bottom: 20px;
  @include respond-to('small'){
    padding: 0;
  }
  & > div{
    & > p{
      font-size: 18px;
      color: #415667;
      @include respond-to('extra-small'){
        font-size: 16px;
      }
      & > img{
        margin: 0 10px;
      }
    }
    a{
      text-decoration: underline;
      &:hover{
        color: #000;
      }
    }
  }
}

.content-hr{
  margin-top: 35px;
  margin-bottom: 35px;
  @include respond-to('medium'){
    margin: 20px 0;
  }
}

.cont-title{
  font-size: 36px;
  font-weight: 800;
  color: #f79319;
  margin: 0;
  @include respond-to('small'){
    font-size: 30px;
  }
  @include respond-to('extra-small'){
    text-align: center;
    font-size: 22px;
  }
}

.aside-navi{
  margin-bottom: 80px;
  @include respond-to('medium'){
    margin-bottom: 20px;
  }
  & > p{
    font-size: 26px;
    color: #f79319;
    margin-bottom: 17px;
  }
  & > ul{
    padding: 0;
    margin: 0;
    list-style: none;
    & > li{
      border-bottom: 1px solid #e0e0e0;
      position: relative;
      &:first-child{
        border-top: 1px solid #e0e0e0;
      }
      &:hover{
        & > a{
          color: #f79319;
        }
        &:after{ width: 100%;}
      }
      & > a{
        font-size: 18px;
        color: #415667;
        display: block;
        line-height: 43px;
        @include transition(all .3s ease-in-out);
      }
      &:after{
        content: " ";
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        width: 0%;
        height: 2px;
        background-color: #f79319;
        margin: 0 auto;
        @include transition(all .3s ease-in-out);
      }
      &.aside-navi_active{
        & > a{
          color: #f79319;
        }
        &:after{ width: 100%;}
      }
    }
  }
}

.wrap-content-recommend{
  margin-bottom: 30px;
  & > span{
    font-size: 26px;
    color: #f79319;
  }
  & > hr{
    border-top: 1px solid #e0e0e0;
  }
}

.recommend-slide__data{
  & > p{
    font-size: 18px;
    color: #415667;
    margin: 0;
    text-align: justify;
  }
  & > span{
    display: inline-block;
    font-size: 18px;
    font-weight: 800;
    color: #f79319;
    margin: 20px 0;
  }
}

.recommend-slide-buttons{
  width: 39px;
  height: 20px;
  position: relative;
}

.recommend-slide-buttons__prev,
.recommend-slide-buttons__next{
  width: 50%;
  height: 100%;
  float: left;
  border: 1px solid #e6e6e6;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  & > i{
    color: #4d6171;
  }
  &:hover{
    background-color: #f79319;
    & > i{
      color: #fff;
    }
  }
}

.recommend-slide-buttons__prev{
  border-left: 1px solid #e6e6e6;
  float: right;
}
