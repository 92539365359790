.wrap-articles{
  max-width: 400px;
  width: 100%;
  min-height: 440px;
  margin-bottom: 30px;
  @include respond-to('small'){
    margin-left: auto;
    margin-right: auto;
  }
}

.articles-box-img{
  height: 200px;
  @include respond-to('small'){
    height: auto;
  }
}

.articles-box-title{
  border-bottom: 1px solid #d9d9d9;
  padding: 15px 0;
  font-size: 24px;
  color: #000;
  font-weight: 800;
  margin: 0;
  line-height: 1;
  @include respond-to('small'){
    font-size: 18px;
  }
}

.articles-box-txt{
  padding: 12px 0;
  margin: 0;
  font-size: 18px;
  color: #415667;
  text-align: justify;
  @include respond-to('small'){
    font-size: 16px;
  }
  & > p{
    margin: 0;
  }
}

.articles-box-btn{
  & > a{
    display: block;
    width: 95px;
    height: 33px;
    @include border-radius(3px);
    text-align: center;
    line-height: 33px;
    font-size: 16px;
    color: #fff;
    background-color: #415667;
    & > i{
      font-size: 13px;
      margin-right: 5px;
      @include transition(all .4s ease);
    }
    &:active{
      @include btn-effect-active
    }
    &:hover{
      & > i{
        transform: translateX(-4px);
      }
    }
  }
}
