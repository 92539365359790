// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $main-color;
  text-decoration: none;

  @include on-event {
    color: #fff;
    text-decoration: none;
    outline: none;
  }
}

.mobile-menu-btn{
  float:left;
}

.site-wrapper{
  width:100%;
}

a.selected-link{
  color: $light-red-color;
}
