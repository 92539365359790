// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
.section-full{
  //padding:0px $padding-base;
  width: 100%;
}

.section-limit{
  width:100%;
  max-width:$max-width;
  margin: 0 auto;
  padding:0px $padding-base;
}

.section-spacer{
  width:100%;
  height:$section-spacer-height;
}

.row-spacer{
  width:100%;
  float:right;
  height:$row-spacer-height;
}

img{
  max-width:100%;
}

.img-not-responsive{
  max-width:auto;
}

.no-padding{
  padding:0;
}

.no-padding-2{
  padding:0;
}

.no-margin-mobile{
  @include respond-to('medium') {
    margin:0px 15px;
  }
}

.no-margin{
  margin:0;
}


.helper-blue{
  width:100%;
  min-height:250px;
  background-color:blue;
  opacity:0.5
}

.helper-red{
  width:100%;
  min-height:250px;
  background-color:red;
  opacity:0.5
}

.helper-green{
  width:100%;
  min-height:250px;
  background-color:green;
  opacity:0.5
}

.hr-full{
  margin:0;
  padding:0;
  border:0;
  width:100%;
  height:$hr-full-height;
  background-color:$hr-full-bg;
  border-bottom:1px solid $hr-border-color;
  margin:$hr-margin;
}

.no-row-padding{
    & > div[class^="col-"]{
      padding:0;
    }
}

.no-margin-bottom{
  margin-bottom:0 !important;
}

iframe{
  max-width:100% !important;
}
