.banner-top-content{
  position: relative;
  width: 100%;
  min-height: 450px;
  @include respond-to('small'){
    min-height: 300px;
  }
  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../images/shadow-banner.png');
    width: 100%;
    height: 185px;
  }
}

.banner-top-content__image{
  background-position: center center;
  background-size: cover;
  height: 450px;
  & > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @include respond-to('small'){
    height: 300px;
  }
}

.banner-top-content__title{
  font-size: 44px;
  font-weight: 800;
  color: #415667;
  position: absolute;
  top: 55%;
  left: 20%;
  transform:translateY(-50%);
}
