footer{
  width:100%;
  position: relative;
  background-color: #dcd9d9;
  z-index: 1;
  -webkit-box-shadow: inset 0px 5px 20px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: inset 0px 5px 20px 0px rgba(0,0,0,0.2);
  box-shadow: inset 0px 5px 20px 0px rgba(0,0,0,0.2);
  padding-top: 70px;
  padding-bottom: 40px;
  @include respond-to('large'){
    padding: 20px 0;
  }
}

.ftr-form-title{
  margin-bottom: 25px;
  & > p{
    font-size: 26px;
    font-weight: 800;
    color: #f7991b;
    line-height: 0.8;
    margin: 0;
    @include respond-to('larger'){
      font-size: 24px;
    }
    &  > span{
      font-size: 18px;
      color: #415667;
      font-weight: 400;
    }
  }
}

.newslatter-input{
  width: 253px;
  height: 38px;
  border: 1px solid #a9a9a9;
  border-bottom: 1px solid #fff;
  background-color: #c6c6c6;
  position: relative;
  @include border-radius(30px);
  color: #415667;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 15px;
  &:after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
  &:focus{
    @include placeholder{
      opacity: 0; transition: opacity 0.3s ease;
    }
  }
  @include placeholder{
    color: #415667;
    font-size: 18px;
    opacity: 1; transition: opacity 0.3s ease;
  }
}

.newslatter-btn{
  margin-top: 22px;
  display: block;
  width: 253px;
  height: 38px;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  border: 0;
  background-color: #f7991b;
  position: relative;
  @include border-radius(30px);
  @include respond-to('small'){
    margin-left: auto;
    margin-right: auto;
  }
  &:active{
    @include btn-effect-active;
  }
  & > i{
    font-size: 18px;
    margin-right: 5px;
    @include transition(all .4s ease);
  }
  &:hover{
    & > i{
      transform: translateX(-4px);
    }
  }
}

.ftr-form{
  @include respond-to('small'){
    text-align: center;
  }
}

.ftr-links-koteret{
  & > .ftr-links-koteret__title{
    font-size: 18px;
    font-weight: 800;
    color: #415667;
    margin-top: 0;
    margin-bottom: 25px;
  }
  & > hr{
    margin: 0;
    border-top: 1px solid #a9a9a9;
    border-bottom: 1px solid #fff;
    margin-bottom: 25px;
    position: relative;
    &:after{
      content: " ";
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      width: 0%;
      height: 1px;
      background-color: #000;
      margin: 0 auto;
      @include transition(all 0.4s);
    }
  }
}

.ftr-wrap-links{
  max-width: 200px;
  @include respond-to('small'){
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  &:hover{
   hr:after{ width: 100%;}
  }
  & > ul{
    padding: 0;
    list-style: none;
    & > li{
        margin-bottom: 10px;
      & > a{
        font-size: 16px;
        color: #415667;
        &:hover{
          color: #000;
        }
      }
    }
  }
}

@include respond-to('extra-small'){
  .ftr-wrap-nav{
    & > .col-xs-6{
      width: 100%;
    }
  }
}

.ftr-check-uncheck{
  position: relative;
  width: 23px;
  height: 23px;
  display: inline-block;
  margin-left: 5px;
  label {
    width: 23px;
    height: 23px;
    cursor: pointer;
    position: absolute;
    background-color: #c6c6c6;
    border: 1px solid #b4b3b3;
    @include border-radius(50%);
    left: 0;
    top: 0;
    &:after {
      content: '';
      width: 12px;
      height: 6px;
      position: absolute;
      top: 6px;
      left: 5px;
      border: 2px solid #415667;
      border-top: none;
      border-right: none;
      background: 0 0;
      opacity: 0;
      transform: rotate(-45deg);
    }
  }
  input[type=checkbox] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }
}

.ftr-checkbox-para{
  display: inline-block;
  & > p{
    font-size: 16px;
    color: #415667;
    margin: 0;
    @include respond-to('larger'){
      font-size: 13px;
    }
  }
}

.hr-footer-bottom{
  border: 0;
  border-top: 1px solid #a9a9a9;
  border-bottom: 1px solid #fff;
  margin-top: 40px;
  margin-bottom: 20px;
}

.credit-info{
  display: inline-block;
  line-height: 2;
  @include respond-to('medium'){
    display: block;
    text-align: center;
  }
}

.credit-info-txt{
  display: inline-block;
  @include respond-to('medium'){
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
  @include respond-to('small'){
    margin-right: 0;
  }
  & > p{
    font-size: 20px;
    color: #415667;
    margin: 0;
    @include respond-to('extra-small'){
      font-size: 14px;
    }
  }
  & a{
    font-size: 20px;
    color: #415667;
    &:hover{
      text-decoration: underline;
    }
  }
}

.wrap-social{
  position: relative;
  display: inline-block;
  margin-right: 25px;
  @include respond-to('large'){
    margin-right: 20px;
  }
  @include respond-to('medium'){
    display: block;
    text-align: center;
    margin: 0;
    margin-bottom: 10px;
  }
  & > a{
    & > i{
      color: #415667;
      margin-left: 15px;
    }
    &:hover{
      & > i.fa-linkedin{
        color: #007bb6;
      }
      & > i.fa-youtube{
        color: #bb0000;
      }
      & > i.fa-facebook{
        color: #3b5998;
      }
    }
  }
}
