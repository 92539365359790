.wrapper-all-content{
  background-color:#fff;
  position: relative;
  width: 100%;
}

.text-white{
  color: #fff;
}

.text-dark-blue{
  color: #415667;
}

.text-orange{
  color: #f7991b;
}

.h2-no-margin{
  margin-top: 0;
  margin-bottom: 0;
}

@include respond-to('small'){
  .mobile-change-color-txt-dark{
    color: #415667 !important;
  }
}

.text-size50-extra{
  font-size: 50px;
  font-weight: 800;
  @include respond-to('large'){
    font-size: 42px;
  }
  @include respond-to('small'){
    font-size: 32px;
  }
  @include respond-to('extra-small'){
    font-size: 26px;
  }
}

.text-size40-extra{
  font-size: 40px;
  font-weight: 800;
  @include respond-to('large'){
    font-size: 32px;
  }
  @include respond-to('small'){
    font-size: 26px;
  }
}

.text-size30{
  font-size: 30px;
  @include respond-to('large'){
    font-size: 28px;
  }
  @include respond-to('small'){
    font-size: 22px;
  }
}

.text-size20{
  font-size: 20px;
}

.text-height-1{
  line-height: 1;
}

.text-style-link,
.cont-link{
  display: inline-block;
  font-size: 18px;
  color: #f7991b;
  font-weight: 800;
  padding-top: 8px;
  border-top: 2px solid #f7991b;
  margin-top: 15px;
  @include respond-to('extra-small'){
    font-size: 16px;
  }
  &:hover,&:active,&:focus{
    color: #f7991b;
  }
}

.text-style-link{
  @include respond-to('medium'){
    margin-bottom: 20px;
  }
}

.cont-link{
  font-size: 20px;
}

.padding-left-50{
  padding-left: 50px;
  @include respond-to('medium'){
    padding-left: 0;
  }
}

.padding-right-50{
  padding-right: 50px;
  @include respond-to('medium'){
    padding-right: 0;
  }
}

.hp-section-welcome__title{
  font-size: 30px;
  color: #f7991b;
  line-height: 1;
  margin-top: 50px;
  @include respond-to('medium'){
    font-size: 28px;
    margin-top: 20px;
  }
  @include respond-to('extra-small'){
    font-size: 22px;
  }
}

.hp-section-welcome__title2{
  font-size: 50px;
  font-weight: 800;
  color: #415667;
  line-height: 0.8;
  margin-bottom: 60px;
  @include respond-to('medium'){
    font-size: 32px;
    margin-bottom: 20px;
  }
  @include respond-to('extra-small'){
    font-size: 22px;
  }
}

.hp-section-welcome__content{
  font-size: 20px;
  color: #415667;
  padding-left: 50px;
  @include respond-to('medium'){
    padding-left: 0;
  }
  @include respond-to('extra-small'){
    font-size: 16px;
  }
}

.hp-section-welcome__btn{
  display: inline-block;
  font-size: 18px;
  color: #f7991b;
  font-weight: 800;
  padding-top: 8px;
  border-top: 2px solid #f7991b;
  margin-top: 30px;
  &:hover{
    color: #f7991b;
  }
}

.hp-section-welcome__spacer{
  height: 100px;
  @include respond-to('small'){
    height: 30px;
  }
}

.hp-section-consultant{
  width: 100%;
  min-height: 700px;
  position: relative;
  background-position: center center;
  background-size: cover;
  @include respond-to('large'){
    min-height: inherit;
    padding: 20px 0;
  }
  @include respond-to('small'){
    background-image:none !important;
  }
}

.hp-section-consultant-data{
  padding-top: 145px;
  display: block;
  @include respond-to('large'){
    padding-top: 0;
  }
}

.hp-section-consultant-data__content{
  margin-top: 40px;
  @include respond-to('small'){
    margin-top: 20px;
  }
}

.hp-wrap-articles{
  position: relative;
  width: 100%;
  min-height: 700px;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(218,222,225,1) 100%);
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(218,222,225,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(218,222,225,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dadee1',GradientType=0 );
  padding-top: 60px;
  padding-bottom: 20px;
  @include respond-to('small'){
    min-height: inherit;
    padding: 20px 0;
  }
}

.wrap-articles__title_margin-bottom{
  margin-bottom: 60px;
  @include respond-to('small'){
    margin-bottom: 20px;
  }
}

.hp-section-consultant-data__title{
  font-size: 18px;
  color: #f7991b;
  margin: 25px 0;
}

#hp-slide-recommend {
  position: relative;

  .slick-slide{
    outline: none;
    padding-top: 10px;
  }
  .slick-dots{
    text-align: right;
    li{
      width:11px;
      height:11px;
      margin:0;
      margin-left:9px;
      button{
        width:11px;
        height:11px;
        border-radius: 50%;
        background-color: #fff;
        opacity:1;
        &:before{
          display:none;
          background-color: red;
        }
        &:hover{
          opacity:1;
          background-color: #f7991b;
        }
      }
      &.slick-active button{
        opacity:1;
        background-color: #f7991b;
      }
    }
  }
}
