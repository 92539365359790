.wrapper-banners{
  position: relative;
  width: 100%;
  height: 850px;
  overflow: hidden;
  @include respond-to('medium'){
    height: 600px;
  }
  @include respond-to('small'){
    height: 400px;
  }
  @include respond-to('extra-small'){
    height: 300px;
  }
  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../images/shadow-banner.png');
    width: 100%;
    height: 185px;
    @include respond-to('small'){
      display: none;
    }
  }
}

.wrap-banner{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include respond-to('small'){
    position: static;
  }
}

.banner-bg{
  width: 100%;
  min-height: 850px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include respond-to('medium'){
    min-height: 600px;
  }
  @include respond-to('small'){
    min-height: 400px;
  }
  @include respond-to('extra-small'){
    min-height: 300px;
  }
}

.banner-content{
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translateY(-50%);
  max-width: 450px;

  @include respond-to('medium'){
    top: 65%;
    left: 5%;
  }
  @include respond-to('small'){
    top: 70%;
    left: auto;
    right: 5%;
    max-width: 80%;
  }
  @include respond-to('extra-small'){
    max-width: 100%;
    padding: 0 10px;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: -12px;
    left: 0;
    right: -25px;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-border-radius: 2%;
    border-radius: 2%;
    z-index: -1;
    -webkit-filter: blur(90px);
    filter: blur(30px);
  }
}

.banner-content__title{
  font-size: 44px;
  color: #415667;
  font-weight: 600;
  line-height: 1;
  @include respond-to('small'){
    font-size: 28px;
    text-shadow: 0px 1px 1px rgba(255, 255, 255, 1);
  }
  @include respond-to('extra-small'){
    font-size: 22px;
  }
}

.banner-content__btn{
  display: inline-block;
  width: 178px;
  height: 48px;
  background-color: #f7991b;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  line-height: 48px;
  text-align: center;
  border-radius: 3px;
  margin-top: 15px;
  @include respond-to('small'){
    width: auto;
    height: auto;
    line-height: initial;
    padding: 5px 8px;
    font-size: 20px;
  }
  @include respond-to('extra-small'){
    font-size: 16px;
  }
  &:active{
    @include btn-effect-active;
  }
}
