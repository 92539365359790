//########### section all wrapper side panel ################
.wrapper-all-aside-panel{
  width: 250px;
  height: 100%;
  position: fixed;
  z-index: 9;
  top: 0;
  left: -250px;
  @include transition(all 0.5s);
}//########### END section all wrapper side panel ################

.text-orange{
  color: #f7991b !important;
}

//########### section wrapper side panel form ################
.wrap-aside-panel{
  width: 250px;
  min-height: 100%;
  height: 100%;
  background-color: #fff;
  -webkit-box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.25);
  box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.25);
  position: relative;
  overflow-x: auto;
  @include transition(all 0.5s);
}//########### END section wrapper side panel form ################

//########### section class imulation open and close ################
.aside-panel__open{
  left: 0;
  z-index: 9999;
  & .aside-panel-tab{
    width: 60px;
    right: -60px;
    z-index: 9;
  }
  & .class-hidden{
    display: none;
  }
  & .class-visible{
    display: block !important;
  }
}//########### END section class imulation open and close ################


//########### section panel tab ################
.aside-panel-tab{
  position: absolute;
  top: 40%;
  right: -90px;
  width: 90px;
  height: 80px;
  background-color: #fff;
  z-index: 1;
  @include border-radius(0 50% 50% 0);
  cursor: pointer;
  -webkit-box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.25);
  box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.25);
}

.aside-panel-tab__icon{
  position: absolute;
  top: 50%;
  right: 15px;
  background-repeat: no-repeat;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAcCAYAAACdz7SqAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAhFJREFUeNq8VsttwkAQXSwKcAmmgpgOIA2AL8nR9im5YVcAVAC55YZ9zAloIJAKQgfQQSghM+itNEK7XoydjPQk8H7efN6M3Rk+vQRKqTNQ2z4/3muf8Qgh4UhYEcbqH4xJN4gyIazhwALO/BkpWymecbozwjeQ4VnrpMXVc44+x+8Fol8jG35bpCfCHmkuUNsHQp/QIyyR7lUb9e+QevXvBJdFhBH+swOp2D8gxCD04SxnpSQVH+4hZftBxBEcMBFLJ0ci4gO0sSEHTnVINVEPUbiIFSJOkIFQaGILB84u0hCKzVFH5SKWw+Hx+ZVVPkH0euhcHKB9GxupAqmPaJWL2DaRyAFr/T3D/hJeDsSzFIRabLeMxz2Bzw0RcaAd6Br2F+jNGKKSxF8gDcVltkg5uimGC++bkxMz2afSZK/6BodSkO5sg4IIE/RzhjN9TWirqe7HnUirqV1WaJOhVijqOMV5Xss5zS71Sjsi6r5lXRJHIEtEKpe2i7sVWihxUQDlmWqvxGhUaLO5qTdvjTTAZUsx/E2DQWckunUUehVruq+Sij1TEKd1Zq/nWN/i0rFFbBd1msTShLRA6mLD2gJr+b3vUxfx+OrrYYZezV2iuZe0FC2iBTbBtCqafDlU2QGIRYv4Fa+6VkjZ3hDhAgKaW3q3VVL9LsxANmvjw8xlZ1G/VDW0bo29WlD7pqS/AgwACoe8USpCjm4AAAAASUVORK5CYII=');
  width: 29px;
  height: 28px;
  display: inline-block;
  @include transform(translateY(-50%));
}

.aside-panel-tab__close-icon{
  position: absolute;
  top: 50%;
  left: 50%;
  color: #415667;
  @include transform(translate(-50%,-50%));
}

.aside-panel-tab__txt{
  font-size: 16px;
  font-weight: 800;
  color: #415667;
  white-space: pre-wrap;
  line-height: 1;
  position: absolute;
  top: 50%;
  @include transform(translateY(-50%));
  right: 50px;
}//########### END section panel tab ################

.aside-panel__title{
  font-size: 18px;
  color: #000;
  line-height: 1;
}

.aside-panel__title-bold{
  font-size: 24px;
  font-weight: 800;
  line-height: 1;
}

//########### class color background box ################
.aside-panel_bg-gray{
  background-color: #f4f4f4;
  border-bottom: 1px solid #d6d6d6;
  border-top: 1px solid #d6d6d6;
  position: relative;
  &:after,
  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
  &:before{
    top: auto;
    bottom: 0;
  }
}//########### END class color background box ################

.aside-panel_padding-vertic{
  padding-top: 20px;
  padding-bottom: 20px;
}

.aside-panel_padding-horiz{
  padding-left: 20px;
  padding-right: 20px;
}

.aside-panel-tel__image{
  position: relative;
  background-repeat: no-repeat;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAhBJREFUeNrEV01SwjAULh329gh6AvAEBC8gbHRJWOnO4QTACQo7d8WlK3ICiSewnsByA26gifOFeZOhJWkDvJlvSkvz3pf3l9dW/+Ep8pRrBabQUeiS558KuYJU2Lkq+3h/jdqexlOFQcn/DFdNQChMXMnEjgRmCj8goHc6VrhRaBHcEsMc73MX5S6eyKCsgHFZ8l4OLPB+irVaVk08YQjk2Kl09Jw22se67JhHqkgMCIG+T7IRzwyxLkNOeZNIoWBYg4ARE8KIhMaZBAfzJRQ1EYEwsjJvlJG4d0koD1mSzTmTYIhpEYiEwLXnQyIBiZAifcJhWvE2Ci/OJJLozBJbxnWr3eB+qvBbVVoOonV+QY85W/TvdVnbTuEunUTfpEo4srtOjnCE19Y5AAQlQVvr0mrP3QYhSip0rtEIRQzjPLqMZCYneoEbk8/Zktw9PrOYlM32AiT21WFuRmcmYSa0IkbS7KqO2hOJTlqpZsx/EoU1L9BKuApo0NYpUR37EjUDyMZuJCBX9xyRaHqHdO7nlLa1YHwgLKLBUGN220XOad1z+7OgfWA2DC0CeIHxWd2Rv6mwqvHgXCSmuL5dikQGT6xO4QmOY5liQ8oxQVWYz4ZJky+wqGT6ytBjjIs76IJrPOdWhexCkzC7nVsVRYeXHN34aMXVJVGQQWhkEVv4fq80ITEGCWb1mblvc/sTYAAlAIz6O6hJuwAAAABJRU5ErkJggg==');
  width: 33px;
  height: 33px;
  display: inline-block;
  margin-top: 7px;
  margin-left: 15px;
}

.aside-panel-mail__image{
  position: relative;
  background-repeat: no-repeat;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAcCAYAAACdz7SqAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAhFJREFUeNq8VsttwkAQXSwKcAmmgpgOIA2AL8nR9im5YVcAVAC55YZ9zAloIJAKQgfQQSghM+itNEK7XoydjPQk8H7efN6M3Rk+vQRKqTNQ2z4/3muf8Qgh4UhYEcbqH4xJN4gyIazhwALO/BkpWymecbozwjeQ4VnrpMXVc44+x+8Fol8jG35bpCfCHmkuUNsHQp/QIyyR7lUb9e+QevXvBJdFhBH+swOp2D8gxCD04SxnpSQVH+4hZftBxBEcMBFLJ0ci4gO0sSEHTnVINVEPUbiIFSJOkIFQaGILB84u0hCKzVFH5SKWw+Hx+ZVVPkH0euhcHKB9GxupAqmPaJWL2DaRyAFr/T3D/hJeDsSzFIRabLeMxz2Bzw0RcaAd6Br2F+jNGKKSxF8gDcVltkg5uimGC++bkxMz2afSZK/6BodSkO5sg4IIE/RzhjN9TWirqe7HnUirqV1WaJOhVijqOMV5Xss5zS71Sjsi6r5lXRJHIEtEKpe2i7sVWihxUQDlmWqvxGhUaLO5qTdvjTTAZUsx/E2DQWckunUUehVruq+Sij1TEKd1Zq/nWN/i0rFFbBd1msTShLRA6mLD2gJr+b3vUxfx+OrrYYZezV2iuZe0FC2iBTbBtCqafDlU2QGIRYv4Fa+6VkjZ3hDhAgKaW3q3VVL9LsxANmvjw8xlZ1G/VDW0bo29WlD7pqS/AgwACoe8USpCjm4AAAAASUVORK5CYII=');
  width: 29px;
  height: 28px;
  display: inline-block;
  margin-top: 7px;
  margin-left: 15px;
}

.aside-panel-mail__link{
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
}

//########### section form ################
.aside-panel-form{
  margin-top: 10px;
  & > .form-group{
    margin-bottom: 10px;
  }
}

.aside-panel-form__input{//inputs size
  height: 39px;
  width: 100%;
  @include placeholder{
    opacity: 1;
    color: #415667;
    @include transition(opacity 0.4s ease);
  }
  &:focus{
    @include placeholder{
      opacity: 0;
      @include transition(opacity 0.4s ease);
    }
  }
}

.aside-panel-form__input_style{//inputs style
  background-color: #f6f6f6;
  color: #415667;
  font-size: 18px;
  padding: 0 15px;
  border: 1px solid #d6d6d6;
  @include border-radius(3px);
  -webkit-box-shadow: inset 0px 3px 10px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: inset 0px 3px 10px 0px rgba(0,0,0,0.1);
  box-shadow: inset 0px 3px 10px 0px rgba(0,0,0,0.1);
}

.aside-panel-form__textarea{//textarea size
  height: 109px;
  padding-top: 7px;
  resize: vertical;
}

.aside-panel-form__input-btn_style{//button style
  background-color: #f7991b;
  border: 0;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  @include border-radius(3px);
  &:active{
    @include btn-effect-active;
  }
}//########### END section form ################

//########### section social ################
.aside-panel-social{
  display: block;
}

.aside-panel-social__box{
  width: 83px;
  border-left: 1px solid #d6d6d6;
  position: relative;
  padding: 16px 0;
  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: -2px;
    width: 1px;
    height: 100%;
    background-color: #fff;
  }
}

.aside-panel__social-title{
  color: #415667;
  font-size: 16px;
  line-height: 1;
}

.aside-panel-social__icon{
  margin-bottom: 8px;
  display: inline-block;
  & > .fa-facebook{
    color: #3b5998;
  }
  & > .fa-youtube{
    color: #bb0000;
  }
  & > .fa-linkedin{
    color: #007bb6;
  }
}//########### END section social ################
